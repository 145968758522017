.CategoryNewsCom img {
  border-radius: 10px;
  object-fit: cover;
  height: 25vh;
}

.CategoryNewsCom h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
}

.catTitle {
  max-width: 320px;
  white-space: nowrap;
  /* display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: horizontal; */
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
