.Signup Card {
  width: 30vw;
  max-height: 50vh;
}
.gbtn {
  height: 45px;
  border-radius: 15px;
}

.fbtn {
  border-radius: 15px;
  height: 45px;
}

.text-center {
  text-align: center;
}

.social-login-btn Button {
  width: 100%;
}

.SignInButton Button {
  width: 100%;
}

.social-login-icon {
  padding: 0 10px;
  color: #145ecd;
  font-size: 25px;
}

.Emailinput {
  border-width: 2px;
  border-radius: 15px;
  border-color: black;
}

.signuptag {
  font-size: 25px;
  font-weight: 500;
}

.Signuptaglink {
  color: #e00e0e;
}

.btntext {
  font-size: 20px;
}

.Accountrequest {
  font-size: 20px;
}

.Signupbtn {
  background-color: #cc0526 !important;
  width: 100%;
  color: white;
  border-radius: 12px;
  height: 50px;
}

.SignBtnText {
  font-size: 20px;
}
