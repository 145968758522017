.specialimg {
  border-radius: 10px;
  width: 100%;
  height: 19vh;
  object-fit: cover;
}

@media only screen and (max-width: 600px) {
  .specialimg-mob {
    width: 42vh;
    object-fit: cover;
    height: auto;
  }
}

.SpecialNewsSection {
  text-align: left !important;
}
