.newsDetailsScreen img {
  object-fit: cover;
  height: 20vh;
}

.new-details-img img {
  border-radius: 10px;
  width: 100%;
  object-fit: cover;
  height: 485px;
}

.also-like-section img {
  border-radius: 20px;
  width: 100%;
}

.text-justify {
  text-align: justify;
}

.newsDetailsScreen .grey-box-ads {
  background-color: #00000025;
  border-radius: 10px;
  width: 100%;
  height: 20vh;
}

.reaction {
  color: rgb(255, 0, 0);
}

.newsDetailsScreen .Skeleton-img {
  width: 25vw;
  height: 25vh;
}

.newsDetailsScreen h1 {
  margin-bottom: -8px;
}
.newsDetailsScreen img {
  margin-top: -5px;
}

.youMayLikeNews h3 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
