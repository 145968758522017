.red-tag-dark-bg {
  background-color: #9b001a;
  width: 100%;
  color: #ffffff;
}
.red-tag-light-bg {
  background-color: #cc0526;
  width: 100%;
}

.red-tag-light-bg a {
  color: #ffffff !important;
}

.hotNewsTagsSection h3 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #ffffff;
}
