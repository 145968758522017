.cartoonimg {
    width: 100%;
    height: 42vh;
    object-fit: cover;
  }
  
  @media only screen and (max-width: 600px) {
    .cartoonimg-mob {
      width: 42vh;
      height: auto;
      object-fit: cover;
    }
  }

  /* @media only screen and (max-width: 900px) {
    .cartoonimg-mobpad {
      width: 65vh;
      height: auto;
      object-fit: cover;
    }
  }
   */