.footer-colorline {
  background-color: #820318;
  height: 1vh;
  width: 100%;
}

.footer-category-link ul,
.footer-quick-link ul,
.footer-contact-link ul {
  padding: 0;
}

.footer-contact-link li {
  padding: 5px 0;
}

.footer-category-link .title,
.footer-category-link .title a,
.footer-quick-link .title,
.footer-quick-link .titile a,
.footer-contact-link,
.footer-contact-link a {
  font-weight: 700;
}

.footer-end-colorline {
  background-color: #820318;
}

.footer-end-colorline p {
  color: #ffffff;
}

.footer-copyright-text a,
.footer-copyright-text a:hover {
  color: #ffffff !important;
}

.footer-copyright-text a:hover {
  color: #000 !important;
}
