.icon {
  padding: 5px 10px;
  color: #000;
}

.icon:hover {
  color: #cc0526;
}
.social-links a:hover {
  color: #cc0526 !important;
}
