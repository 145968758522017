.hotImg {
  width: 100%;
  border-radius: 10px;
  height: 40vh;
}

.headline-text {
  text-align: left;
}

.content-box {
  background-color: rgba(0, 0, 0, 0.116);
  border-radius: 5px;
  width: 100%;
  min-height: 100px;
  margin: 3px 0px;
  text-align: left;
  padding: 10px;
}

.empty-content {
  background-color: rgba(0, 0, 0, 0.116);
  width: 100%;
  height: 80px;
  border-radius: 10px;
}

.hot-news-container {
  text-align: left;
}

.weather-icon {
  font-size: 40px;
  padding: 10px;
}
.weather-icon img {
  width: 50px;
}
/* 
.blue{
    color: rgb(0, 174, 255);
} */

.orange {
  color: rgb(255, 115, 0);
}

.card-content {
  width: 100%;
  margin-bottom: 10px;
}

.weather-information {
  background-color: #f5f5f5;
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  height: 225px;
}

.hot-news-container .textWrap p:not(:first-of-type) {
  display: none;
}

.hot-news-container .textWrap p:first-of-type {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
