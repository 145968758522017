.topnewsimg {
  height: 25vh;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

@media only screen and (max-width: 1000px) {
  .topnewsimg-mob {
    width: 120%;
    object-fit: cover;
    height: auto;
  }
}

@media only screen and (max-width: 900px) {
  .topnewsimg-mob {
    width: 65vh;
    object-fit: cover;
    height: auto;
  }
}

@media only screen and (max-width: 600px) {
  .topnewsimg-mob {
    width: 42vh;
    object-fit: cover;
    height: auto;
  }
}
