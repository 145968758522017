.text-center {
  text-align: center;
}

.input-full-width input {
  width: 100%;
}

.RequestButton Button {
  width: 100%;
}

.Emailinput {
  border-width: 2px;
  border-radius: 15px;
  border-color: black;
}

.fptag {
  font-size: 25px;
  font-weight: 600;
}

.btntext {
  font-size: 20px;
}

.Accountrequest {
  font-size: 20px;
}

.reqbtn {
  background-color: #cc0526 !important;
  width: 100%;
  color: white;
  border-radius: 12px;
  height: 50px;
}

.reqBtnText {
  font-size: 20px;
}

.resetlink {
  font-size: 20px;
  font-weight: 500;
}
