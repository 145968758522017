.head-box {
  background-color: black;
  width: 100%;
  height: 10vh;
}

.head-colorline {
  background-color: #cc0526;
  height: 3vh;
}

.logo-img {
  width: 200px;
}

.header-signin-btn {
  background-color: #cc0526 !important;
}

.header-bottom-color-bar {
  background-color: #cc0526;
  height: 1vh;
}

.social-icons {
  color: rgba(0, 0, 0, 0.5);
  padding: 0 10px;
}

.head-top-btn {
  float: right;
}

.head-top-btn Button {
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: #ffffff !important;
}

.nameButton {
  background-color: #000 !important;
  color: #fff !important;
}
