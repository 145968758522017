.categoryScreen a {
  width: 100%;
}
.categoryViewimg {
  border-radius: 10px;
}
.catContent {
  overflow: hidden;
  max-height: 10vh;
}

.adAreaView {
  background-color: #f5f5f5;
  border-radius: 10px;
  width: 100%;
  padding: 20px;
}

.categoryScreen .textWrap p:not(:first-of-type) {
  display: none;
}

.categoryScreen .textWrap p:first-of-type {
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.categoryScreen .categoryViewimg a {
  width: 100%;
}
