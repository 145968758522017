.grey-box-ads {
  background-color: #00000025;
  border-radius: 10px;
  height:60px ;
}

.ads-area-right {
  padding-right: 50px;
  margin: 10px;
}
