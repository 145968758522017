.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  width: 100%;
}

.grid-item {
  width: 100%;
  padding-left: 20;
  margin: 0;
  text-align: left;
}

.input-border {
  border-color: black;
  border-width: 2px;
}

.see-more-btn {
  color: black;
  border-radius: 25px;
  background-color: white;
  margin-left: 150px;
  color: #000000 !important;
  border-color: #0000002a !important;
}

.news-Head {
  width: 100%;
}

.grey-box-bg {
  background-color: rgba(0, 0, 0, 0.062);
  border-radius: 10px;
}

.para1 {
  font-size: 25px;
}

.para2 {
  font-size: 35px;
  font-weight: 700;
  margin-top: -45px;
}

.selectcurrency {
  width: 100%;
  border-radius: 10px;
  border: none;
  background-color: #efefef;
}

.selectText {
  font-size: 20px;
}

.breaker {
  color: #979797;
}

.inputvalue {
  border: none;
}

.GossipSection .text-content p:not(:first-of-type) {
  display: none;
}

.GossipSection .text-content p:first-of-type {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.GossipSection .css-175rt9u-JoyInput-root {
  border: 0px;
}

.gossipImg {
  width: 100%;
  border-radius: 10px;
}
@media only screen and (max-width: 600px) {
  .gossipImg-mob {
    width: 42vh;
    object-fit: cover;
    height: auto;
  }
}
