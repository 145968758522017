.underTopNews img {
  border-radius: 10px;
  object-fit: cover;
  height: 25vh;
  width: 100%;
}
.underTitle {
  margin: 0;
  max-width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
