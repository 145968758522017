.gbtn {
  height: 45px;
  border-radius: 15px;
}

.fbtn {
  border-radius: 15px;
  height: 45px;
}

.text-center {
  text-align: center;
}

.social-login-btn Button {
  width: 100%;
}

.input-full-width input {
  width: 100%;
}

.SignInButton Button {
  width: 100%;
}

.social-login-icon {
  padding: 0 10px;
  color: #145ecd;
  font-size: 25px;
}

.Emailinput {
  border-width: 2px;
  border-radius: 15px;
  border-color: black;
}

.emailveritag {
  font-size: 25px;
  font-weight: 600;
}

.btntext {
  font-size: 20px;
}

.Accountrequest {
  font-size: 20px;
}

.SignInbtn {
  background-color: #e00e0e;
}

.SignBtnText {
  font-size: 20px;
}

.vericode {
  font-size: 20px;
  font-weight: 500;
}
