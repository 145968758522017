.hot-tags-home-icon a {
  color: #cc0526 !important;
}

.hot-tag-bottom-color-bar {
  width: 100%;
  background-color: #d9d9d9;
  height: 1vh;
}

