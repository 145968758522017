.ReactPanel .emojiList {
  cursor: pointer;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
}

.ReactPanel .emoji {
  padding: 5px;
  font-size: 1.3rem;
}

.ReactPanel .count {
  padding: 5px;
  font-size: 0.9rem;
}
