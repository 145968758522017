.red-line {
  background-color: #cc0526;
  color: #fff;
  min-width: 100%;
}

.grey-box {
  background-color: #00000025;
  border-radius: 10px;
}

.red-line {
  text-align: left;
  width: 100%;
}
.red-line h3 {
  padding: 10px 20px;
}

.head-news-section-img img {
  border-radius: 10px;
  width: 100%;
  height: 340px;
}

.headling-text {
  text-align: left;
}

.subscribe-banner img {
  width: 100%;
}

.grey-box-ads-headnews {
  background-color: #00000025;
  border-radius: 10px;
  /* height: 350px; */
}
